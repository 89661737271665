import React, { useState } from 'react';
import { PicFrame } from './studio.style';

function MyStudio() {
    return(
        <>
            <PicFrame></PicFrame>
        </>
    )
}

export default MyStudio;