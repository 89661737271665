import React from "react";

function Portfolio() {
    return(
        <>
            <h1>Portfolio section coming soon!</h1>
        </>
    )
}

export default Portfolio;